import { memo } from "react";
import Circles, { type Horizontal, type Vertical } from "./Circles";
import cs from "classnames";
import { cn } from "@lib/utils";
interface Isomorphic<T extends React.ElementType> {
  as?: T;
  children?: React.ReactNode;
}

const Opposite = {
  top: "bottom",
  bottom: "top",
  left: "right",
  right: "left",
} as const;

function TicketingBackdrop<T extends React.ElementType = "main">({
  as,
  noCircles,
  className,
  position = "top right",
  fade = "out",
  ...props
}: Isomorphic<T> &
  Omit<React.ComponentPropsWithoutRef<T>, keyof Isomorphic<T>> & {
    noCircles?: boolean;
    position?: Extract<
      React.ComponentProps<typeof Circles>["split"],
      `${string} ${string}`
    >;
    fade?: React.ComponentProps<typeof Circles>["fade"];
  }) {
  const [vertical, horizontal] = position.split(" ") as [Vertical, Horizontal];
  const Component = as || "main";
  return (
    <Component
      {...props}
      className={cn(
        "relative overflow-hidden bg-gradient-to-r from-stone-100 to-stone-300 font-body dark:from-jacarta-800 dark:to-blue-950 dark:text-white",
        className
      )}
    >
      {!noCircles && (
        <div
          className={cs(
            "absolute isolate w-screen stroke-zinc-400/40 dark:stroke-sky-800",
            {
              "top-0": vertical === "top",
              "bottom-0": vertical === "bottom",
              "left-0": horizontal === "left",
              "right-0": horizontal === "right",
            }
          )}
        >
          <Circles
            amount={75}
            width={"100%"}
            split={`${Opposite[vertical]} ${Opposite[horizontal]}`}
            fade={fade}
            opacity={0.55}
          />
        </div>
      )}
      {props.children}
    </Component>
  );
}

export default memo(TicketingBackdrop);
